import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography } from '@mui/material'
import { formatCustom } from 'helpers/format/formatNumber'
import React, { useState } from 'react'
interface IDetailModalSettings {
    id: string,
    open: boolean,
    handleClose: () => void
}
const DetailModalSettings = ({ open, handleClose, id }: IDetailModalSettings) => {
    const [infoWhitelist, setInfoWhitelist] = useState({
        contractId: '',
        contractName: '',
        contractAddress: '',
    })
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfoWhitelist({ ...infoWhitelist, [event.target.name]: event.target.value })
    }
    const [abiContract, setAbiContract] = useState('')
    const [methods, setMethods] = useState([])
    const { contractId, contractName, contractAddress } = infoWhitelist
    const onAddWhiteelist = () => {
        try {

        } catch (err) {
            console.log(err)
        }
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // sx={{ padding: 10 }}
            maxWidth={"sm"}
            fullWidth

        >
            <DialogTitle id="alert-dialog-title" justifyContent={"center"} fontSize={"2.5rem"}>
                Transfer to Dapp
            </DialogTitle>
            <DialogContent>

                <Box mt={1} />
                <Typography>Contract ID:</Typography>
                <Box mt={1} />
                <TextField
                    type={"text"}
                    variant="outlined"
                    required
                    fullWidth
                    name='contractId'
                    value={contractId}
                    onChange={onChange}
                    placeholder="Enter contract ID"
                />
                <TextField
                    type={"text"}
                    variant="outlined"
                    required
                    fullWidth
                    name='contractId'
                    value={contractId}
                    onChange={onChange}
                    placeholder="Enter contract ID"
                />
                <FormControl fullWidth>
                    {/* <NumericFormat customInput={TextField}
                value={amount}
                variant="outlined"
                thousandSeparator={true}
                onChange={onChange}
                autoComplete="off"
                width={"100%"}
                placeholder="Enter amount"
            /> */}
                </FormControl>

                <Box mt={1} />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button variant='contained' size='large' onClick={onAddWhiteelist}>Deposit</Button>
                {/* <Button variant='contained' onClick={handleClose} autoFocus>
            Close
        </Button> */}
            </DialogActions>
        </Dialog>
    )
}

export default DetailModalSettings