import { Button } from '@mui/material'
import { AuthContext } from 'contexts/AuthUserContext'
import { TronContext } from 'contexts/TronContext'
import React, { useContext, useEffect } from 'react'
const TIME_EXPIRE = 24 * 60 * 60
const EventLogin = () => {
    const { onLogin } = useContext(AuthContext)
    const { action, isConnected, onConnect, tronWeb } = useContext(TronContext)
    const onSignMessage = async (time: number) => {
        if (isConnected && (window as any).tronWeb) {
            var signature = await (window as any).tronWeb.trx.sign(tronWeb.toHex(`rls_tronsave_${time}`));
            onLogin({
                address: action.address,
                timestamp: time,
                signature,
                duration_sec: TIME_EXPIRE
            })
        }
    }
    return (
        <Button variant='contained' onClick={() => onSignMessage(new Date().getTime())}>Login</Button>
    )

}

export default EventLogin