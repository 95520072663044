import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Dialog(theme) {
    return {
        MuiDialog: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(0,0,0,0.8)",
                },
                paper: {
                    backgroundColor: theme.palette.background.neutral,
                }
            },
        },
    };
}
