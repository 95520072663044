import { useMutation } from '@apollo/client'
import { Box, Button, FormControl, Input, InputLabel, TextField, Typography } from '@mui/material'
import { MUTATION_TRANSFER_BALANCE } from 'api/schema'
import { ReloadContext } from 'contexts/ReloadContext'
import { TronContext } from 'contexts/TronContext'
import { formatInput } from 'helpers/format/formatInputAmount'
import { formatCustom, formatTrxToSun } from 'helpers/format/formatNumber'
import { splitError } from 'helpers/format/splitError'
import { notifyToastify } from 'helpers/toastNotify'
import React, { useContext, useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import styled from 'styled-components'
import breakpoints from 'theme/breakpoints'

const MIN_TRANSFER = Number(process.env.REACT_APP_TRANSFER) || 1
const DetailDappTransfer = ({ balance, apiKey }: { balance: number, apiKey: string }) => {
    const { onReload } = useContext(ReloadContext)
    const { action } = useContext(TronContext)
    const [value, setValue] = useState({
        from: apiKey,
        to: '',
        amount: ''
    })
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    const onResetInput = () => {
        setValue({ from: '', to: "", amount: '' })
    }
    const { from, to, amount } = value
    const [mutationTransfer, { data, loading, error }] = useMutation(MUTATION_TRANSFER_BALANCE)
    console.log({ balance })
    const onTransfer = (from, to, amount) => {
        console.log({ from, to, amount }, formatTrxToSun(formatInput(amount)))
        if (from === "" || to === '' || amount === '') {
            notifyToastify({ type: "error", text: "You must enter full fields" })
            return;
        }
        if (MIN_TRANSFER > formatInput(amount)) {
            notifyToastify({ type: "error", text: "Min transfer is 1TRX" })
            return;
        }
        if (balance < formatInput(amount)) {
            notifyToastify({ type: "error", text: "Balance not enough" })
            return;
        }
        try {
            mutationTransfer({ variables: { from, to, amount: formatTrxToSun(formatInput(amount)) } }).then(() => {
                notifyToastify({ type: "success", text: "Transfer successfully" })
                onResetInput()
                onReload()
            }, (error) => {
                console.log("ERROR TRANSFER TO PROJECT", error)
                notifyToastify({ type: "error", text: splitError(error) })
            })
        } catch (error) {
            console.log("ERROR TRANSFER TO PROJECT", error)
        }
    }
    const isDisabled = useMemo(() => {
        if (formatInput(amount) <= MIN_TRANSFER || formatInput(amount) > balance) {
            return true
        }
        return false
    }, [value])

    return (
        <Wrap>
            <Typography fontWeight={600}>Transfer to My account</Typography>
            <Box mt={3} />
            {/* <Typography>From *</Typography>
            <Box mt={1} />
            <TextField id="outlined-basic" variant="outlined" required fullWidth value={from} name={"from"} onChange={onChange} />
            <Box mt={3} />

            <Typography>To * </Typography>
            <Box mt={1} />
            <TextField id="outlined-basic" variant="outlined" fullWidth value={to} name="to" onChange={onChange} />
            <Box mt={2} /> */}
            <Typography>Amount</Typography>
            <Box mt={1} />
            <FormControl fullWidth>
                <NumericFormat customInput={TextField}
                    value={amount}
                    variant="outlined"
                    thousandSeparator={true}
                    onChange={onChange}
                    autoComplete="off"
                    name='amount'
                />
            </FormControl>
            {/* <TextField id="outlined-basic" variant="outlined" fullWidth value={amount} name="amount" onChange={onChange} /> */}
            <Box mt={1} />

            <Typography fontSize={12} style={{ fontStyle: "italic" }}>Balance: <span>{formatCustom(balance, "0,0.00")}</span> TRX</Typography>
            <Box mt={2} />
            <Button
                variant="contained" fullWidth size='medium' onClick={() => onTransfer(apiKey, action.address, amount)}
                disabled={isDisabled}
            >Transfer</Button>
        </Wrap>
    )
}

export default DetailDappTransfer
const Wrap = styled.div`
    max-width: 250px;
    width: 100%;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    @media (max-width: ${breakpoints.values.xl}px) {
        position: static;
        top: 0;
        transform: translateY(0);
        padding:30px 16px 0;
        max-width: 100%;

    }
`