import { useMutation } from '@apollo/client'
import { Box, Button, FormControl, Input, InputLabel, TextField, Typography } from '@mui/material'
import { MUTATION_ADD_PROJECT } from 'api/schema'
import { ReloadContext } from 'contexts/ReloadContext'
import { notifyToastify } from 'helpers/toastNotify'
import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import JsonError from 'constanst/JsonError'
import { splitError } from 'helpers/format/splitError'
import breakpoints from 'theme/breakpoints'
const DashboardAddDapp = () => {
    const { onReload } = useContext(ReloadContext)
    const [value, setValue] = useState({
        name: '',
    })
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    const { name } = value
    const checkNameLength = useMemo(() => {
        if (name !== '' && (name.length < 6 || name.length > 30)) {
            return true
        }
        return false
    }, [name])
    const [mutationAddProject, { data, loading, error }] = useMutation(MUTATION_ADD_PROJECT)
    const onRegister = (name: string) => {
        if (name === "") {
            notifyToastify({ type: "error", text: "Name is required" })
            return;
        }
        try {
            mutationAddProject({
                variables: { projectName: name }
            }).then(() => {
                notifyToastify({ type: "success", text: "Added Dapp success" })
                setValue({ name: '' })
                onReload()
            }, (error) => {
                console.log("ERROR REGISTERING DAPP", error)
                if (error) {
                    notifyToastify({ type: "error", text: splitError(error) })
                }

            })
        } catch (error) {
            console.log("ERROR REGISTER DAPP", error)
        }
    }
    return (
        <Wrap>
            <Typography fontWeight={600}>Register A Dapp</Typography>
            <Box mt={3} />
            <Typography>Dapp name *</Typography>
            <Box mt={1} />
            <TextField id="outlined-basic" variant="outlined" required fullWidth value={name} name={"name"} onChange={onChange}
                inputProps={{ maxLength: 30, minLength: 6 }}

            />
            <Box mt={1} />

            {checkNameLength ?
                <Typography fontSize={"0.75rem"} color="red" fontStyle={"italic"}>Lengt must be in 6 - 30 letter </Typography>
                : null
            }
            {/* <FormControl variant="standard">
                <InputLabel htmlFor="component-simple">Dapp name</InputLabel>
                <Input id="component-simple" value={''} />
            </FormControl> */}
            <Box mt={3} />

            {/* <Typography>Reciept contract </Typography>
            <Box mt={1} />
            <TextField id="outlined-basic" variant="outlined" fullWidth value={contract} name="contract" onChange={onChange} />
            <Box mt={2} /> */}
            <Button variant="contained" fullWidth size='medium' onClick={() => onRegister(name)} disabled={checkNameLength}>Register</Button>
        </Wrap>
    )
}

export default DashboardAddDapp
const Wrap = styled.div`
    max-width: 250px;
    width: 100%;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    @media (max-width: ${breakpoints.values.xl}px) {
        position: static;
        top: 0;
        transform: translateY(0);
        padding:30px 16px 0;
        max-width: 100%;
    }
`