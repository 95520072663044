import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link } from '@mui/material'
import { TronContext } from 'contexts/TronContext'
import React, { useContext, useState } from 'react'

const ConnectModal = () => {
    const { isConnected, onConnect } = useContext(TronContext)
    return (
        <Grid>
            <Dialog
                open={!isConnected}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title" textAlign={"center"} >
                    {"You must login TRONLINK"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Wait, something’s wrong!
                        To continue using the system, please install and login TronLink account
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    {/* <Button onClick={() => onConnect()}>Install Tronlink</Button> */}
                    <Link href='https://www.tronlink.org/' target={"_blank"}>Install TronLink</Link>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default ConnectModal