import { useMutation } from '@apollo/client'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { MUTATION_DELETE_PROJECT } from 'api/schema'
import { ReloadContext } from 'contexts/ReloadContext'
import { splitError } from 'helpers/format/splitError'
import { notifyToastify } from 'helpers/toastNotify'
import React, { useContext, useMemo, useState } from 'react'
interface IDashboardDeleteDapp {
    apiKey: string,
    open: boolean,
    handleClose: () => void,
    name: string
}
const DashboardDeleteDapp = ({ apiKey, open, handleClose, name }: IDashboardDeleteDapp) => {
    const { onReload } = useContext(ReloadContext)
    const [rename, setRename] = useState('')
    const [error, setError] = useState(false)
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRename(e.target.value)
    }
    const [mutationDeleteProject] = useMutation(MUTATION_DELETE_PROJECT)
    const onDelete = (apiKey, name) => {
        if (name !== rename) {
            setError(true)
            return;
        }
        if (!apiKey) {
            return;
        }
        try {
            mutationDeleteProject({ variables: { apiKey } }).then(() => {
                notifyToastify({ type: "success", text: "Project deleted successfully" })
                handleClose()
                onReload()
            }, (error) => {
                notifyToastify({ type: "error", text: splitError(error) })
            })
        } catch (error) {
            console.log("ERROR DEPOSIT", error)
            handleClose()
        }
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // sx={{ padding: 10 }}
            maxWidth={"sm"}
            fullWidth

        >
            <DialogTitle id="alert-dialog-title" justifyContent={"center"} fontSize={"2rem"}>
                <Typography variant='h4'>Delete Dapp</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography >You must enter name project <span style={{ fontWeight: "700" }}>{name}</span> then confirm delete</Typography>
                <Box mt={1} />
                <TextField id="outlined-basic" variant="outlined" required fullWidth value={rename}
                    onChange={onChange}
                    onFocus={() => setError(false)}
                />
                <Box mt={1} />
                {
                    (error) ?
                        <Typography color={"red"} fontSize={"0.7rem"} fontStyle={"italic"} >Dapp not match</Typography>
                        : null
                }
                <Box mt={3} />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button variant='contained' size='large' onClick={() => onDelete(apiKey, name)}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DashboardDeleteDapp