import { useLazyQuery } from '@apollo/client';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { QUERY_SETTINGS_CONTRACT } from 'api/schema';
import { ReloadContext } from 'contexts/ReloadContext';
import { TronContext } from 'contexts/TronContext';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import DetailDappSettingModalDelete from './settings/DetailDappSettingModalDelete';
import DetailDappSettingModalEdit from './settings/DetailDappSettingModalEdit';

interface Column {
    id: "counter" | 'id' | "name" | "address" | "enableWhitelistMethod" | "methodsAllowed";
    label: string;
    minWidth?: number;
    align?: 'right' | "center" | 'left';
    format?: (value: any) => any;
}
const DetailDappSettingContract = ({ apiKey }: { apiKey: string }) => {
    const { action } = useContext(TronContext)
    const { isReload } = useContext(ReloadContext)
    const [idContractChoosed, setIdContractChoosed] = useState<string | undefined>(undefined)
    const [idEditContractChoosed, setIdEditContractChoosed] = useState<string | undefined>(undefined)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [getProjectActivity, { data, loading, error }] = useLazyQuery(QUERY_SETTINGS_CONTRACT)
    const rows = useMemo(() => {
        if (!data) {
            return []
        }
        if (data && data.rls_user_project_info_get) {
            const { recipient_contracts: listContracts } = data.rls_user_project_info_get
            if (listContracts) {
                let counterId = 0
                const dataMapping = listContracts.map((activity) => {
                    const { id, name, address, methods_allowed: methodsAllowed, abi, enable_whitelist_methods } = activity
                    counterId += 1
                    return {
                        counter: counterId,
                        id,
                        name,
                        address,
                        methodsAllowed,
                        abi,
                        enableWhitelistMethod: enable_whitelist_methods ?? false
                    }
                })
                return dataMapping ?? []
            }
            return []
        }
    }, [data])
    const totalItems = useMemo(() => {
        if (!data) {
            return []
        }
        if (data && data.rls_user_project_activity_history) {
            const { total } = data.rls_user_project_activity_history
            return total
        }
        return 0
    }, [data])
    useEffect(() => {
        getProjectActivity(
            {
                variables: { page, pageSize: rowsPerPage, apiKey },
                // fetchPolicy: "no-cache"
                fetchPolicy: 'network-only', // Used for first execution
                nextFetchPolicy: 'cache-first', // Used for subsequent executions
            },

        )
    }, [isReload, rowsPerPage, apiKey, page])
    interface IHandleDeleteWhitelist {
        apiKey: string,
        contractId: string
    }
    const handleEditWhitelist = ({ apiKey, contractId }: IHandleDeleteWhitelist) => {
        try {
            setIdEditContractChoosed(contractId)
        } catch (err) {
            console.log("ERROR DELETE WHITELIST", err)
        }
    }
    const handleDeleteWhitelist = ({ apiKey, contractId }: IHandleDeleteWhitelist) => {
        try {
            setIdContractChoosed(contractId)
        } catch (err) {
            console.log("ERROR DELETE WHITELIST", err)
        }
    }
    const columns: readonly Column[] = useMemo(() => [
        {
            id: 'counter',
            label: 'ID',
        },
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'address',
            label: 'Contract address',
            minWidth: 170,
            format: (value: string) => <Typography typography={"table"} textTransform={"capitalize"}>{value}</Typography>,
        },
        {
            id: 'methodsAllowed',
            label: 'Method Allowed',
            align: "center",
            format: (value: any) =>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {value.map((value: any) => value + ', ')}
                </div>
        },
        {
            id: 'enableWhitelistMethod',
            label: 'Whitelist Method',
            align: "center",
            format: (value: any) => {
                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {value ? "Enabled" : "Disabled"}
                    </div>
                )
            }
        },

        {
            id: 'id',
            label: 'Action',
            align: 'center',
            format: (value: string) =>
                <div style={{ display: "flex", justifyContent: "center", gap: 15 }}>
                    <EditOutlinedIcon style={{ cursor: "pointer" }} sx={{ fontSize: "1rem" }} color="info" onClick={() => handleEditWhitelist({ apiKey, contractId: value })} />
                    <DeleteOutlineOutlinedIcon style={{ cursor: "pointer" }} color="error" sx={{ fontSize: "1rem" }} onClick={() => handleDeleteWhitelist({ apiKey, contractId: value })} />
                </div>
        },

    ], [action.address])

    const getAbi = (id: string): { abi: string, methodsAllowed?: string[] } => {
        if (rows.length) {
            return rows.filter((row) => row.id === id)?.[0]
        }
        return {
            abi: '',
            methodsAllowed: []
        }
    }
    const getEnableMethods = (id: string): boolean => {
        if (rows.length) {
            return rows.filter((row) => row.id === id)?.[0]?.enableWhitelistMethod ?? false
        }
        return false
    }
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant='h5' marginBottom={3}>List contracts</Typography>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length > 0 && rows
                            .map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column, i) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={`${column.id + index + i}`} align={column.align} >
                                                    {column.format
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {
                idContractChoosed && <DetailDappSettingModalDelete apiKey={apiKey} contractId={idContractChoosed} open={!!idContractChoosed} handleClose={() => setIdContractChoosed(undefined)} />
            }
            {
                idEditContractChoosed && <DetailDappSettingModalEdit
                    apiKey={apiKey}
                    contractId={idEditContractChoosed}
                    open={!!idEditContractChoosed}
                    abi={getAbi(idEditContractChoosed)?.abi ?? ''}
                    handleClose={() => setIdEditContractChoosed(undefined)}
                    initMethods={getAbi(idEditContractChoosed)?.methodsAllowed ?? []}
                    initIsEnabled={getEnableMethods(idEditContractChoosed)}
                />
            }
        </Paper>
    )
}

export default memo(DetailDappSettingContract)