
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material'
import { useTheme, alpha } from '@mui/material/styles';
import React, { useState } from 'react'
import styled from 'styled-components';
import { IDapp } from 'types'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DashboardModalDeposit from './DashboardModalDeposit';
import { useNavigate } from 'react-router-dom';
import { formatCustom, formatSunToTRX } from 'helpers/format/formatNumber';
import DashboardDeleteDapp from './DashboardDeleteDapp';
import { formatStringAddress } from 'helpers/format/formatStringAddress';

const DashboardItem = ({ name, apiKey, paymentAddress, balance, }: IDapp) => {
    const navigate = useNavigate()
    const PRIMARY_MAIN = "#380f473d";
    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const onDelete = (id) => {
        try {
            setOpenDelete(true)
        } catch (error) {
            console.log("ERROR DELETE DAPP", error)
        }
    }
    const onRedirect = (e) => {
        e.preventDefault()
        navigate(`../details/${apiKey}`)
    }
    return (
        <>
            <Card sx={{ backgroundColor: PRIMARY_MAIN }}>
                <CardActionArea>
                    <CardContent>
                        <Grid container justifyContent={"space-between"} >
                            <Grid item onClick={(e) => onRedirect(e)} >
                                <Typography variant='h5'>{name}</Typography>
                            </Grid>
                            <Grid display="flex" gap={1}>
                                <Button color='info' variant='outlined' onClick={() => setOpen(true)}>Transfer</Button>
                                <Button color='error' onClick={() => onDelete(apiKey)}  ><DeleteOutlineIcon /></Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider sx={{ my: 1 }}></Divider>

                    <div onClick={(e) => onRedirect(e)} style={{ cursor: "pointer" }}>
                        <CardContent >
                            <ItemContent>
                                <Typography fontWeight={600}>API key</Typography>
                                <Typography >{apiKey}</Typography>
                            </ItemContent>
                            <ItemContent>
                                <Typography fontWeight={600}>Balance</Typography>
                                <Typography >{formatCustom(formatSunToTRX(balance), "0,0.00")} TRX</Typography>
                            </ItemContent>
                            <ItemContent>
                                <Typography fontWeight={600}>Payment address</Typography>
                                <Typography >{formatStringAddress(paymentAddress, 7)} </Typography>
                            </ItemContent>
                        </CardContent>

                    </div>
                </CardActionArea>
            </Card>
            <DashboardModalDeposit open={open} handleClose={() => setOpen(false)} id={apiKey} name={name} />
            <DashboardDeleteDapp open={openDelete} handleClose={() => setOpenDelete(false)} apiKey={apiKey} name={name} />
        </>
    )
}

export default DashboardItem
const ItemContent = styled.div`
    margin-bottom:10px;

`