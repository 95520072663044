import { useLazyQuery, useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system'
import { QUERY_USER_INFO } from 'api/schema';
import { ReloadContext } from 'contexts/ReloadContext';
import dayjs from 'dayjs';
import { fData, formatCustom, formatSunToTRX, fShortenNumber } from 'helpers/format/formatNumber';
import { formatStringAddress } from 'helpers/format/formatStringAddress';
import useResponsive from 'helpers/hooks/useResponsive';
import numeral from 'numeral';
import React, { useContext, useEffect, useMemo } from 'react'
import AccountDeposit from 'sections/account-page/AccountDeposit';
import AccountTableActivity from 'sections/account-page/AccountTableActivity';
import styled from 'styled-components'
const AccountPage = () => {
    const { isReload } = useContext(ReloadContext)
    const [getUserInfo, { data, loading, error }] = useLazyQuery(QUERY_USER_INFO)
    // console.log('data user', data)
    const users = useMemo(() => {
        if (data && data.rls_user_info_get) {
            const { address, last_login: lastLogin, balance, total_deposit_amount: totalDeposit, total_withdraw_amount: totalWithdraw } = data.rls_user_info_get
            return {
                address,
                balance,
                lastLogin,
                totalDeposit,
                totalWithdraw,
            }
        }
        return {}
    }, [data])
    const { address, balance, lastLogin, totalDeposit, totalWithdraw } = users
    useEffect(() => {
        getUserInfo({
            fetchPolicy: "network-only",
            nextFetchPolicy: "cache-first"
        })
    }, [isReload])
    return (
        <>
            <Container maxWidth="xl">
                <Grid container bgcolor={"#fff"} padding={{ lg: 8, xs: 0 }}>
                    <Typography variant='h5' mb={3}>Account Detail</Typography>
                    <Grid container bgcolor={"rgba(0,0,0,0.05)"} p={3} borderRadius={2}>
                        <Grid container  >
                            <Grid item lg={6} py={2} xs={12}>
                                <Typography >Address: <span style={{ fontWeight: 700 }}>{formatStringAddress(address || '', 5)}</span></Typography>
                            </Grid>
                            <Grid item lg={6} py={2} xs={12}>
                                <Typography >Balance: <span style={{ fontWeight: 700 }}>{numeral(formatSunToTRX(balance)).format("0,0")} TRX</span></Typography>
                            </Grid>
                        </Grid>

                        <Grid container  >
                            <Grid item lg={6} py={2} xs={12}>
                                <Typography >Total Deposit: <span style={{ fontWeight: 700 }}>{formatCustom(formatSunToTRX(totalDeposit), "0,0")} TRX</span></Typography>
                            </Grid>
                            <Grid item lg={6} py={2} xs={12}>
                                <Typography >Last Login: <span style={{ fontWeight: 700 }}>{dayjs(lastLogin).format("HH:mm a  - DD MMM YYYY")}</span></Typography>
                                {/* <Typography >Total Withdraw: <span style={{ fontWeight: 700 }}>{formatCustom(formatSunToTRX(totalWithdraw), "0,0")} TRX</span></Typography> */}
                            </Grid>

                        </Grid>
                        {/* <Grid container lg={12} >
                            <Grid item lg={6} py={2}>
                                <Typography >Last Login: <span style={{ fontWeight: 700 }}>{dayjs(lastLogin).format("HH:mm a  - DD MMM YYYY")}</span></Typography>
                            </Grid>
                        </Grid> */}
                    </Grid>
                    <Grid container>
                        <Typography variant='h5' mt={5} mb={1}>User Activity</Typography>
                    </Grid>
                    <Grid container bgcolor={"rgba(0,0,0,0.05)"} borderRadius={2}>
                        <AccountTableActivity />
                    </Grid>
                </Grid>
            </Container>
            <AccountDeposit />
        </>
    );
}

export default AccountPage
const Wrap = styled.div`
    

`