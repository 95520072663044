import { useLazyQuery, useQuery } from '@apollo/client';
import { Card, CardHeader, Grid, Typography, useTheme } from '@mui/material';
import { Container } from '@mui/system'
import { QUERY_USER_PROJECTS } from 'api/schema';
import { ReloadContext } from 'contexts/ReloadContext';
import { useContext, useEffect, useMemo } from 'react';
import DashboardAddDapp from 'sections/dashboard/DashboardAddDapp';
import DashboardItem from 'sections/dashboard/DashboardItem';
import styled from 'styled-components'
import { IDapp } from 'types';
const DashboardApp = () => {
    const { isReload } = useContext(ReloadContext)
    const [getUserProject, { data, loading, error }] = useLazyQuery(QUERY_USER_PROJECTS, {
        fetchPolicy: "no-cache"
        // fetchPolicy: 'network-only', // Used for first execution
        // nextFetchPolicy: 'cache-first', // Used for subsequent executions 
    })
    const dapps: IDapp[] = useMemo(() => {
        if (data && data.rls_user_projects_get) {
            const { data: dataDapps } = data.rls_user_projects_get
            return dataDapps.length ? dataDapps.map((dapps) => {
                const { api_key: apiKey,
                    payment_address: paymentAddress,
                    balance,
                    project_name: name
                } = dapps
                return {
                    name,
                    apiKey,
                    balance,
                    paymentAddress
                }
            }) : []
        }
        return []
    }, [data])
    useEffect(() => {
        getUserProject()
    }, [isReload])
    return (
        <>
            <Container maxWidth="xl">
                <Grid container bgcolor={"#fff"} padding={{ lg: 8, xs: 0 }} borderRadius={4} >
                    <Typography variant='h4' mb={3}>Your Dapp</Typography>
                    <Grid container

                        display="flex"
                        spacing={2}
                        bgcolor="white"
                    >
                        {
                            dapps.map((dapp, index) =>
                                <Grid item lg={6} xs={12} key={index} >
                                    <DashboardItem {...dapp} />
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
            <DashboardAddDapp />
        </>
    );
}

export default DashboardApp
const Wrap = styled.div`
    

`