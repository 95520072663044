import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import useResponsive from 'helpers/hooks/useResponsive';
import Scrollbar from 'components/core/Scollbar';
import NavSection from 'components/core/NavSection';
import navConfig from './navConfig';
import { Cookies } from 'react-cookie';
import { TronContext } from 'contexts/TronContext';
import SvgColor from 'components/core/SvgColor';
import { AuthContext } from 'contexts/AuthUserContext';
import logo from 'assets/images/logo.png'
import styled from 'styled-components';


// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};
const icon = (name) => <SvgColor src={`/assets/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export default function Nav({ openNav, onCloseNav }) {
    const { action: { address } } = useContext(TronContext)
    const { onLogout } = useContext(AuthContext)
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'lg', '');
    const Cookie = new Cookies()
    const isLogined = Cookie.get(`token-${address}`)
    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 1, display: 'inline-flex' }}>
                {/* <Logo /> */}
            </Box>

            {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box> */}
            <WrapLogo>
                <img src={logo} alt='logo' />
            </WrapLogo>
            <NavSection data={navConfig} />

            <Box sx={{ flexGrow: 1 }} />
            <Box display={"flex"} marginTop="auto">

            </Box>
            {!isLogined ?
                <NavSection data={[{
                    title: 'Login',
                    path: '/login',
                    icon: icon('ic_lock')
                }]} />
                :
                <div onClick={() => onLogout()}>
                    <NavSection data={[{
                        title: 'Logout',
                        pathname: "/login",
                        icon: icon('ic_lock')
                    }]} />
                </div>

            }
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}



        </Box>
    );
}
const WrapLogo = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    img{ 
        max-width: 120px;
        filter: invert(100%);
    }

`