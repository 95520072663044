import { useMutation } from '@apollo/client'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { MUTATION_DETETE_WHITELIST_CONTRACT } from 'api/schema'
import { ReloadContext } from 'contexts/ReloadContext'
import { notifyToastify } from 'helpers/toastNotify'
import React, { useContext } from 'react'
interface IDetailDappSettingModalDelete {
    apiKey: string,
    open: boolean,
    handleClose: () => void,
    contractId: string
}
const DetailDappSettingModalDelete = ({ apiKey, open, handleClose, contractId }: IDetailDappSettingModalDelete) => {
    const { onReload } = useContext(ReloadContext)
    const [onDeleteWhitelist] = useMutation(MUTATION_DETETE_WHITELIST_CONTRACT, {
        onCompleted: () => {
            handleClose();
            onReload()
            notifyToastify({ type: "success", text: "Deleted whitelist successfully" })
        }
    })
    interface IHandleDeleteWhitelist {
        apiKey: string,
        contractId: string
    }
    const handleDeleteWhitelist = ({ apiKey, contractId }: IHandleDeleteWhitelist) => {
        try {
            // console.log({ apiKey, contractId })
            onDeleteWhitelist({ variables: { apiKey, contractId } })
        } catch (err) {
            console.log("ERROR DELETE WHITELIST", err)
        }
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ padding: 10 }}
            maxWidth={"sm"}
            fullWidth

        >
            <DialogTitle id="alert-dialog-title" justifyContent={"center"} >
                Confirm delete Contract
            </DialogTitle>
            <DialogContent>
                {/* <Typography >To *</Typography>
        <Box mt={1} />
        <TextField id="outlined-basic" variant="outlined" required fullWidth value={id}
            InputProps={{
                readOnly: true,
            }}
        /> */}
                <Box mt={1} />
                <Typography fontSize={"0.75rem"} style={{ fontStyle: "italic" }}><span>When you confirm step is not rollback</span></Typography>

            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button variant='contained' color='warning' onClick={() => handleDeleteWhitelist({ apiKey, contractId })}>Delete</Button>
                <Button variant='contained' onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DetailDappSettingModalDelete