import React, { createContext, useEffect, useMemo, useState } from 'react'
import TronWeb from 'tronweb'

const mainOptions = {
    fullNode: 'https://api.nileex.io',
    solidityNode: 'https://api.nileex.io',
    eventServer: 'https://api.nileex.io'
};
const tronWeb = new TronWeb({
    fullNode: mainOptions.fullNode,
    solidityNode: mainOptions.solidityNode,
    privateKey: '',
    eventServer: mainOptions.eventServer
})

// eslint-disable-next-line global-require
window.Buffer = window.Buffer || require("buffer").Buffer;

export const TronContext = createContext({
    onConnect: () => { },
    isConnected: true,
    action: {
        address: ''
    },
    tronWeb
})

const TronContextWrap = ({ children }) => {
    const [action, setAction] = useState({
        address: '',
    })
    const eventConnect = async () => new Promise((res) => {
        let attempts = 0;
        // eslint-disable-next-line prefer-const
        let maxAttempts = 20;
        const checkTron = () => {
            if (
                (window as any).tronWeb &&
                (window as any).tronWeb.defaultAddress.base58
            ) {
                res(true);
                return;
            }
            // eslint-disable-next-line no-plusplus
            attempts++;
            if (attempts >= maxAttempts) {
                res(false);
                return;
            }
            setTimeout(checkTron, 100);
        };
        checkTron();
    })
    const onConnect = async () => {
        let tronExist = await eventConnect();
        console.log({ tronExist })
        if (tronExist) {
            const address = (window as any).tronWeb.defaultAddress.base58
            if (address) {
                setAction({
                    address,
                })
            }
        } else {
            setAction({
                address: '',
            })
        }
    };
    useEffect(() => {

        onConnect();

    }, [(window as any).tronWeb])
    const isConnected = useMemo(() => {
        if (action.address) {
            return true;
        }
        return false;
    }, [action.address])
    return (
        <TronContext.Provider value={{
            onConnect,
            action,
            isConnected,
            tronWeb
        }}>
            {children}
        </TronContext.Provider>
    )
}

export default TronContextWrap